<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                海通证券携手非凸科技，共同探讨私募生态圈的共建共赢
              </p>
              <div class="fst-italic mb-2">2023年11月21日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >海通证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/61/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    11月12日，海通证券数字金融部邀上海分公司与非凸科技共同承办的“科技赋能投资”沙龙活动，暨“乘舟听海算法季上海站”顺利举行。嘉宾就算法交易的未来发展以及私募生态圈的共建共赢展开了深度探讨，并对未来行业发展与合作进行了展望。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/61/02-姜来.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随着全市场铺展交易设备与服务资源，对于私募管理人的服务已然进入红海竞争。各类交易服务已经很难成为吸引客户的亮点，而是逐渐成为开展业务的门槛。券商需要一整套完整的客户服务方案来满足投资者不同层次的需要，实现服务质量以及服务效率的平衡。以此业态分析为切入点，海通证券数字金融部姜来详细介绍了海通证券在包含后台基础建设、中台策略服务和客户端打造的交易服务上的新进建设成果。他认为，算法交易服务逐渐成为券商服务客户的新模式，使用算法的客户已经从私募机构逐渐扩展延伸到各类客群，并已根据不同客户需求和应用场景展开了个性化交易策略定制。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/61/03-非凸.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随后，非凸科技从多个维度详细介绍了算法如何赋能交易。非凸科技坚持以客户为中心，深刻理解用户需求与场景，保持与行业伙伴同频交流，致力于为券商、私募、公募等大型金融机构提供一站式智能交易领域服务解决方案。非凸智能交易总线旨在赋能券商机构，共同建设算法及交易的生态圈，让使用者拥有更友好、更智能、更多选择的交易体验。非凸算法能力已得到百余家机构客户的认可，同时，非凸拥有更具适配性的技术延展力、更充分的算法工具选择、以及更丰厚的联合展业资源。非凸底仓增强算法也即将上线海通证券，欢迎管理人申请试用。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/61/04-正瀛.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近年来，交易所监管趋严，角度更全面、政策更细化，对交易控制有更高的要求。正瀛资产认为，在这一背景下，算法交易的风控优势得以凸显。算法交易通过风控前置模块对报单速率、委托笔数、委撤比等实现灵活精准的控制。同时，算法交易灵活度高，能够快速适应新的监管政策，并做出及时调整，使得交易符合管理人的业务需求，同时也满足监管要求。正瀛资产是最早使用非凸算法的机构，与非凸共同经历了磨合与成长，其算法绩效优势明显，算法系统稳定性强，期待未来还有更多深入合作。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/61/05-白文杰.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    海通证券上海分公司白文杰表示，为加深与持牌机构的业务合作，上分持续不断整合各类资源，并成立专业团队做好服务需求的对接。同时，他表达了分公司对于持牌机构业务的重视和希望能够与更多优秀的私募管理人开展合作的诉求。
                  </p>
                  <p>
                    未来，非凸科技与海通证券将建立更深层次的合作，发挥各自资源优势，持续技术创新，共建私募生态圈，助力中国数智化交易发展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News61",
};
</script>

<style></style>
